import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from './button'

const MainContainerOuter = styled.div``

const ListingItemOuterContainer = styled.div`
  background: #fff;
  overflow: hidden;

  margin: 1em 0 !important;
`

const ItemTitle = styled.h3`
  margin-bottom: 0.25em;
`
const ItemExcerpt = styled.p`
  margin-bottom: 0.65em;
  color: #666;
  font-size: 0.9em;
`

const ImageContainer = styled.div``

const BodyContainer = styled.div`
  padding: 1.25em;
  padding-left: 1.75em !important;
`

const SingleItem = ({ itemList }) => {
  const menuItems = itemList.map((item, index) => (
    <ListingItemOuterContainer
      key={index}
      activeClassName={'active'}
      className={'row  overflow-hidden'}
    >
      <ImageContainer className="col-12 col-lg-3">
        <GatsbyImage
          image={getImage(item.heroImage)}
          alt={item.serviceTitle}
          style={{ marginLeft: '-15px', marginRight: '-15px', height: '100%' }}
        />
      </ImageContainer>

      <BodyContainer className="col-12 col-lg-9 brd-clr-bottom">
        <ItemTitle>{item.serviceTitle}</ItemTitle>
        <ItemExcerpt>{item.excerpt}</ItemExcerpt>

        {item && item.slug ? (
          <Button
            to={`/${item.slug}`}
            color={'secondary'}
            title={'Learn more about ' + item.serviceTitle}
          >
            Learn More
          </Button>
        ) : null}
      </BodyContainer>
    </ListingItemOuterContainer>
  ))
  return menuItems
}

const ItemListing = ({ itemList }) => {
  return (
    <>
      <MainContainerOuter>
        <SingleItem itemList={itemList} />
      </MainContainerOuter>
    </>
  )
}

export default ItemListing
